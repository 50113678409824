<template>
  <div>
    <Modal :options="{width: '45vw', close: true}" @close="$emit('close')" >
    <div class="title"> Reactivar Solicitud </div>
      <div class="body">
        <p class="text-center">Estas por reactivar la solicitud ID:
            <strong>{{ solicitud.solicitud_id }}</strong> al fondeador:  <strong>{{ solicitud.fondeador.nombre }}</strong> ,<br/>¿Deseas continuar?
         </p>         
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <template>
              <button type="button" class="btn btn-primary mr-2" @click="confirmar()"> Confirmar </button>
              <button type="button" class="btn btn-danger mr-2" @click="$emit('close')" > Cerrar </button>
            </template>
          </div>
        </div>
      </div>        
    </Modal>    
  </div>
</template>
<script>

import Modal from "@/components/Modal";
import config from "@/apps/formalizacion/configuracion/solicitud";
import apicofinanciamiento from "@/apps/formalizacion/api/v2/cofinanciamiento";

export default {
    components:{
        Modal,
    },
    props:{
        solicitud:null,
    },
    methods:{
      async confirmar(){
        try {
          let solicitud = this.solicitud;
          solicitud.estatus = 'reactivar';
          await apicofinanciamiento.actualizar_estatus(this.solicitud._id, solicitud); 
          this.$emit('close');             
        } catch (error) {
            this.$log.info('error', error);
            this.$helper.showAxiosError(error,'Error');           
        }
      }
    },
    // mounted(){
    //     this.$log.info('solicitud id ', this.solicitud);
    // }
}
</script>

<style>

</style>